import React from "react";
import "./AgriFreshMainBanner.css";
import AgriImage from "../../../../images/SegmentAgriImage.png";

export default function AgriFreshMainBanner() {
  return (
    <div>
      <div>
        <div className="AgriFreshMain">
          <img className="AgriFreshMain-home-image" alt="" src={AgriImage} />
          <div className="AgriFreshMain-image-text-box">
            <div className="AgriFreshMain-image-background-text">
              <h1 className="AgriFreshMain-image-topic">Fresh Produce</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
