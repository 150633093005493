import React from "react";
import "./AgriFreshImages.css";
import Image1 from "../../../../images/FreshProduce1.png";
import Image2 from "../../../../images/FreshProduce2.png";
import Image3 from "../../../../images/FreshProduce3.png";
import Image4 from "../../../../images/FreshProduce4.png";

const AgriFreshImages = () => {
  const images = [
    { src: Image1, aos: "fade-right", duration: 700, delay: 0 },
    { src: Image2, aos: "fade-up", duration: 700, delay: 100 },
    { src: Image3, aos: "fade-up", duration: 700, delay: 100 },
    { src: Image4, aos: "fade-left", duration: 700, delay: 300 },
  ];

  return (
    <div className="agriFresh-ImgLayer">
      {images.map((image, index) => (
        <div
          key={index}
          className="agriFresh-ImgCard"
          data-aos={image.aos}
          data-aos-duration={image.duration}
          data-aos-delay={image.delay}
        >
          <img
            src={image.src}
            alt={`Image ${index + 1}`}
            className="agriFresh-Img"
          />
        </div>
      ))}
      <p
        className="agriFresh-Info"
        data-aos="zoom-in"
        data-aos-duration="800"
        data-aos-delay="100"
      >
        The flagship Jawatte outlet which offers the full range of fresh
        vegetables, fruits, specialty condiments and other essentials as well as
        dine-in and takeaway options, is designed to present a world-class
        shopping experience for discerning customers. The remaining 05 Fresheez
        outlets which offer mainly made-to-order Juices have become a symbol of
        healthy lifestyle choices among modern consumers.
      </p>
    </div>
  );
};

export default AgriFreshImages;
