import React from "react";
import AgriFreshMainBanner from "../templates/OurSegments/AgriProduce/AgriFresh/AgriFreshMainBanner";
import AboutFreshProduce from "../templates/OurSegments/AgriProduce/AgriFresh/AboutFreshProduce";
import AgriFreshImages from "../templates/OurSegments/AgriProduce/AgriFresh/AgriFreshImages";

function FreshProduce() {
  return (
    <div>
      <AgriFreshMainBanner />
      <AboutFreshProduce />
      <AgriFreshImages />
    </div>
  );
}

export default FreshProduce;
