import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutFreshProduce.css";

export default function AboutFreshProduce() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="agriFresh-layout-42">
        <div className="agriFresh-content2">
          <div
            className="agriFresh-column2"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="agriFresh-heading">Fresh Produce</div>
          </div>
          <div className="agriFresh-column3">
            <div className="agriFresh-text">
              <p
                className="agriFresh-this-was-done"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                CIC’s Fresheez Outlet has since opening the first outlet have
                single handedly transformed the way Sri Lankan consumers
                perceive fresh produce. Since the inception, the Fresheez brand
                has differentiated itself through its commitment to quality and
                consistency, which has earned the brand a loyal following.
              </p>
              <p className="agriFresh-this-was-done">&nbsp;</p>
              <p
                className="agriFresh-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                Another standout feature of the Fresheez concept is the dine-in
                and takeaway options providing made-to-order healthy fresh fruit
                juices, mixes and salads to inspire consumers to make healthy
                food choices part and parcel of their lifestyle. Thanks to this
                holistic approach, the Fresheez brand today has become
                synonymous with the premium grade fresh produce for discerning
                customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
